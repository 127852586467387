export enum FeatureFlag {
  favoriteTokens = 'favoriteTokens',
  navBar = 'navBar',
  nft = 'nfts',
  redesign = 'redesign',
  tokens = 'tokens',
  tokenSafety = 'tokenSafety',
  traceJsonRpc = 'traceJsonRpc',
  multiNetworkBalances = 'multiNetworkBalances',
}
